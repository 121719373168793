<template>
  <div class="caseMain-wrap70">
    <div class="head">
      <header2></header2>
    </div>
    <div class="caseMain-wrap70content">
      <div class="main-wrap">
        <div class="content">
          <div class="regHd">
            <div class="s1"></div>
            家长信息注册
          </div>
          <div class="tabs">
            <div class="li-tab" @click="cur=1" :class="{active:cur==1}">
              <img src="../../assets/images/login-parent.png"/>
              <div class="t1">家长</div>
            </div>
          </div>
          <div class="Tab" v-show="cur==1">
            <el-form
                ref="ruleForm"
                :model="ruleForm"
                status-icon
                :rules="rules"
                label-width="100px"
            >
              <div class="reg-warp">
                <div class="personalB" style="height: 200px">
                  <div class="p-lf">个人信息</div>
                  <div class="p-rg">
                    <el-form-item label="姓名" prop="familyName">
                      <el-input v-model="ruleForm.familyName" style="width: 450px"></el-input>
                      <span class="test"></span>
                    </el-form-item>
                    <el-form-item label="头像" prop="">
                      <div class="portraitList">
                        <img v-for="(item,index) in portraitList" :key="index"
                             :class="{'onClickStyle':clickFlag == index}" @click="onClickItem(item,index)"
                             :src="item.url" alt=""/>
                      </div>
                    </el-form-item>
                    <el-form-item label="性别" prop="">
                      <el-select v-model="ruleForm.gender" placeholder="请选择" style="width: 450px">
                        <el-option
                            v-for="item in stateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="parentaccount">
                  <div class="p-lf">账户信息</div>
                  <div class="p-rg">

                    <el-form-item label="手机号" prop="familyMobileNumber">
                      <el-input v-model="ruleForm.familyMobileNumber" style="width: 450px"></el-input>
                      <span class="test">手机号作为用户身份ID，只能输入一次且不能修改，请认真输入</span>
                    </el-form-item>
                    <el-form-item label="验证码" prop="famVerifCode">
                      <el-input v-model="ruleForm.famVerifCode" style="width: 327px;margin-right: 10px"></el-input>
                      <el-button class="btn-reg" type="primary" style="border:0px solid #06A8C9"
                                 @click="getValCodePar" :disabled="disabledA">{{codeTitleA}}
                      </el-button>
                    </el-form-item>
                    <el-form-item label="设置密码" prop="pass">
                      <el-input v-model="ruleForm.pass" style="width: 450px"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="passWord">
                      <el-input v-model="ruleForm.passWord" style="width: 450px"></el-input>
                    </el-form-item>

                  </div>
                </div>
                <div class="parentaccount">
                  <div class="p-lf">关联孩子信息</div>
                  <div class="p-rg">
                    <div class="tip">家长需要跟孩子账号进行关联，为了安全，需要往孩子手机上发送一个验证码，请将孩子手机收到的验证码填写</div>

                    <el-form-item label="孩子姓名" prop="studentName">
                      <el-input v-model="ruleForm.studentName" style="width: 450px"></el-input>
                    </el-form-item>
                    <el-form-item label="孩子手机号" prop="studentMobileNumber">
                      <el-input v-model="ruleForm.studentMobileNumber" style="width: 450px"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="stuVerifCode">
                      <el-input v-model="ruleForm.stuVerifCode" style="width: 327px;margin-right: 10px"></el-input>
                      <el-button class="btn-reg" type="primary" style="border:0px solid #06A8C9"
                                 @click="getValCodeStu" :disabled="disabledB">{{codeTitleB}}
                      </el-button>
                    </el-form-item>


                  </div>
                </div>
              </div>
              <div class="btn-warp">
                <el-button type="primary" @click="regForm('ruleForm')">提 交</el-button>
              </div>
            </el-form>

          </div>
        </div>
      </div>
    </div>

    <el-dialog title="注册提示" :visible.sync="successDialog" :close-on-click-modal="false" width="30%">
      <div class="dialogHd"
           style="text-align: center;height: 50px; line-height: 50px;font-size: 20px;font-weight: bold">
        <el-icon color="#359552" style="vertical-align: middle" :size="50">
          <circle-check-filled/>
        </el-icon>
        注册成功
      </div>
      <div style="line-height: 30px;padding-top:50px;padding-bottom: 50px;box-sizing: border-box; text-align: center">
        恭喜！{{ ruleForm.familyName }}家长，您已经成功注册！<br>
        登录后您可看到孩子的学习与成长情况！
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="regObj()" type="primary">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>


</template>


<script>
import header2 from '@/components/layout/header2.vue'
import {sendPhoneCodeObj,parRegObj,findProviceCityObj,findScreenGradeObj} from '@/api/login'

export default {
  name: '',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.passWord !== '') {
          this.$refs.ruleForm.validateField('passWord');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      disabledA: false,
      disabledB: false,
      codeTitleA:'获取验证码',
      codeTitleB:'获取验证码',

      findProviceCityData:[],
      findScreenGradeData:[],
      defaultData:{   //联级选择
        value: 'value',
        label: 'label',
        children: 'children'
      },
      successDialog: false,
      AuthorstuName: '',
      time: 5,
      btntxt: "发送验证码",
      clickFlag: -1,
      clickFlagB: -1,
      cur: 1,//默认选中第一个tab
      stateList: [
        {
          name: '男',
          id: 1
        },
        {
          name: '女',
          id: 2
        }
      ],
      portraitList: [
        {id: 1, url: require('../../assets/images/m1.png')},
        {id: 2, url: require('../../assets/images/m2.png')},
        {id: 3, url: require('../../assets/images/m3.png')},
        {id: 4, url: require('../../assets/images/m4.png')},
        {id: 5, url: require('../../assets/images/m5.png')},
      ],
      value: [],
      ruleForm: {
        familyName: "",//家长姓名
        profilePhotoPath: "",//头像存储路径
        gender: "",//性别
        familyMobileNumber: "",//家长手机号
        famVerifCode: "",//家长手机验证码
        passWord: "",//密码
        studentName: "",//学生姓名
        studentMobileNumber: "",//学生手机号码
        stuVerifCode: "",//学生手机验证码
      },
      rules: {
        verifCodePar: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
        ],
        familyName: [
          {
            required: true,
            message: '请输入家长姓名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '家长姓名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        studentName: [
          {
            required: true,
            message: '请输入孩子姓名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '孩子姓名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        familyMobileNumber: [
          {
            required: true,
            message: '家长手机号码不能为空',
            trigger: 'blur',
          },
          {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号'},
          {
            min: 1,
            max: 30,
            message: '用户名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        famVerifCode: [
          {
            required: true,
            message: '请输入家长手机验证码',
            trigger: 'blur',
          },
        ],
        stuVerifCode: [
          {
            required: true,
            message: '请输入学生手机验证码',
            trigger: 'blur',
          },
        ],
        studentMobileNumber: [
          {
            required: true,
            message: '学生手机号码不能为空',
            trigger: 'blur',
          },
          {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号'},
          {
            min: 1,
            max: 30,
            message: '用户名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        stuName: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '用户名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        nickName: [
          {
            required: true,
            message: '请输入昵称',
            trigger: 'blur',
          },
        ],
        profilePhotoPath: [
          {
            required: true,
            message: '请选择用户头像',
            trigger: 'blur',
          },
        ],
        pass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        passWord: [
          {required: true, message: '请确认密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
          {validator: validatePass2, trigger: 'blur', required: true}
        ],
        mobileNumber: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'blur',
          },
          {pattern: /^1[3456789]\d{9}$/, message: '请输入正确是手机号'},
        ],


        code: [
          {
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 4,
            message: '验证码长度必须为4个字符',
            trigger: 'blur',
          },
        ],
      },
      parentrules: {
        stuName: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '用户名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        nickName: [
          {
            required: true,
            message: '请输入昵称',
            trigger: 'blur',
          },
        ],
        profilePhotoPath: [
          {
            required: true,
            message: '请选择用户头像',
            trigger: 'blur',
          },
        ],
        pass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        passWord: [
          {required: true, message: '请确认密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
          {validator: validatePass2, trigger: 'blur', required: true}
        ],
        mobileNumber: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'blur',
          },
          {pattern: /^1[345678]\d{9}$/, message: '请输入正确是手机号'},
          {
            min: 1,
            max: 30,
            message: '用户名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],


        code: [
          {
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 4,
            message: '验证码长度必须为4个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.findProviceCity()
    this.findScreenGrade()

  },
  mounted() {
    this.AuthorstuName = window.localStorage.getItem("AuthorstuName");

  },
  methods: {
    //家长获取验证码
    getValCodePar(){
      let obj = {
        mobile:this.ruleForm.familyMobileNumber,
        type:2// 家长
      }
      sendPhoneCodeObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          });
          this.disabledA = true;
          this.codeTitleA = "请稍候...";
          setTimeout(() => {
            this.doLoopA(60)
          }, 500)
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
    //家长获取验证码
    getValCodeStu(){
      let obj = {
        mobile:this.ruleForm.studentMobileNumber,
        type:3// 学生
      }
      sendPhoneCodeObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          });
          this.disabledB = true;
          this.codeTitleB = "请稍候...";
          setTimeout(() => {
            this.doLoopB(60)
          }, 500)
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
    //验证码倒计时
    doLoopA: function(seconds) {
      let _this = this
      seconds = seconds ? seconds : 60;
      this.codeTitleA = seconds + "s后获取";
      let countdown = setInterval(() => {
        if (seconds > 0) {
          _this.codeTitleA = seconds + "s后获取"
          --seconds;
        } else {
          _this.codeTitleA = "获取验证码";
          _this.disabledA = false;
          clearInterval(countdown);
        }
      }, 1000);
    },
    doLoopB: function(seconds) {
      let _this = this
      seconds = seconds ? seconds : 60;
      this.codeTitleB = seconds + "s后获取";
      let countdown = setInterval(() => {
        if (seconds > 0) {
          _this.codeTitleB = seconds + "s后获取"
          --seconds;
        } else {
          _this.codeTitleB = "获取验证码";
          _this.disabledB = false;
          clearInterval(countdown);
        }
      }, 1000);
    },
    xbChange($event) {
      this.ruleForm.gender = $event
    },

    ProviceCityChange() {
      this.vals=findProviceCityObj(this.selectedOptions, this.findProviceCityData);
      this.vals.map(item=>{
        this.name = item.name;
        this.id = item.id
        console.log(this.id,'9900');
        console.log(this.name,'9911');
      })

    },
    changefn(value) {
      this.ruleForm.areA=JSON.stringify(value)
      this.ruleForm.area=value

      console.log(this.ruleForm.area,'这是选中的地区')
      //console.log(JSON.stringify(value),'3333这是选中的地区')
    },
    //点击改变文字颜色
    onClickItem(item, index) {
      this.clickFlag = index;
      this.ruleForm.profilePhotoPath = item.id;
      console.log(item.id, '获取头像ID')
    },
    GradeChang($event){
      this.ruleForm.gradeId = $event;
    },
    // 请求获取短信接口
    sendcode() {
      this.time = 30;
      this.timer();
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = "倒计时" + this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "发送验证码";
        this.disabled = false;
      }
    },
    findProviceCity() {

      findProviceCityObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          this.findProviceCityData = res.data
          console.log(res)
        }
      })

    },
    findScreenGrade() {

      findScreenGradeObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          this.findScreenGradeData = res.data
          console.log(res)
        }
      })

    },
    regForm(formName) {
      localStorage.setItem('AuthorstuName', this.ruleForm.stuName)
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            familyName: this.ruleForm.familyName,
            profilePhotoPath: this.ruleForm.profilePhotoPath,
            gender: this.ruleForm.gender,
            familyMobileNumber: this.ruleForm.familyMobileNumber,
            famVerifCode:this.ruleForm.famVerifCode,
            passWord: this.ruleForm.passWord,
            studentName: this.ruleForm.studentName,
            studentMobileNumber: this.ruleForm.studentMobileNumber,
            stuVerifCode: this.ruleForm.stuVerifCode,
          }
          parRegObj(params).then((res) => { // eslint-disable-line no-unused-vars
            if (res.code == 200) {
              this.successDialog = true
              console.log(res)
            } else{
              this.message = res.message
              this.$message.warning(this.message);
            }
          })
        }
      })

    },
    regObj() {
      localStorage.removeItem("AuthorstuName");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("status");
      localStorage.removeItem("Authorization");
      this.successDialog = false
      this.$router.replace({
        path: "/",
        meta: {allowBack: false}
      });
    },




  },
  components: {
    // 图标
    header2,

    //注册图标组件名称
  }
}
</script>
<style lang="scss" scoped>
.caseMain-wrap70 {
  width: 100%;
  display: flex;
  background-size: 100% 100%;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: hidden;

  .head {
    width: 100%;
    height: 70px;
    position: fixed;
    z-index: 0;
  }

  .caseMain-wrap70content {
    width: 100%;
    position: fixed;
    /*固定定位*/
    top: 70px;
    bottom: 0;
    overflow-y: auto;

  }
}

.regHd {
  width: 100%;
  height: 36px;
  line-height: 26px;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #238bff;
  margin-top: 20px;
  display: flex;

  .s1 {
    display: block;
    width: 4px;
    height: 26px;
    margin-right: 10px;
    background-color: #238bff;
  }
}

.tabs {
  width: 100%;
  height: 90px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  .active {
    border-radius: 50px;
    border: 1px solid #F77134;
    background: #FFFCB2;
    color: #ff6820;
  }

  .li-tab {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border: 1px solid #bbb;
    margin: 0px 25px 0 25px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    cursor: pointer;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;
    }

    .t1 {
      text-align: center;
    }
  }


}
.btn-reg {
  height: 40px;
  color: #FFF;
}
.Tab {
  display: block;
  width: 100%;
  margin-top: 10px;

  .reg-warp {
    width: 90%;
    margin: auto;
    padding: 0 50px;
    box-sizing: border-box;
    overflow-y: auto;

    .test {
      margin-left: 30px;
      color: #b1bcd6;
    }

    .personal {
      width: 100%;
      display: flex;
      margin-top: 20px;

      .p-lf {
        height: 500px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 500px;

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;

          }
        }

        .onClickStyle {
          width: 50px;
          height: 50px;
          background-color: #238bff;
          border: 2px solid #238bff;
          border-radius: 50px;

          img {

          }
        }
        .onClickStyleB {
          width: 50px;
          height: 50px;
          background-color: #238bff;
          border: 2px solid #238bff;
          border-radius: 50px;

          img {

          }
        }

        //background-color: #238bff;
      }
    }
    .personalB {
      width: 100%;
      display: flex;
      margin-top: 20px;

      .p-lf {
        height: 200px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 300px;

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;

          }
        }

        .onClickStyle {
          width: 50px;
          height: 50px;
          background-color: #238bff;
          border: 2px solid #238bff;
          border-radius: 50px;

          img {

          }
        }
        .onClickStyleB {
          width: 50px;
          height: 50px;
          background-color: #238bff;
          border: 2px solid #238bff;
          border-radius: 50px;

          img {

          }
        }

        //background-color: #238bff;
      }
    }

    .account {
      width: 100%;
      display: flex;
      margin-top: 40px;

      .p-lf {
        height: 320px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 320px;

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;
          }
        }

        //background-color: #238bff;
      }
    }

    .parentpersonal {
      width: 100%;
      display: flex;
      margin-top: 20px;

      .p-lf {
        height: 190px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 190px;

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;
          }
        }

        //background-color: #238bff;
      }
    }

    .parentaccount {
      width: 100%;
      display: flex;
      margin-top: 40px;

      .p-lf {
        height: 230px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 230px;

        .tip {
          width: 96%;
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          margin: auto auto 10px auto;
          background: rgba(255, 251, 229, 0.8);
          border: 1px solid #FF9B36;
          opacity: 1;
          color: #D50007;
        }

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;
          }
        }

        //background-color: #238bff;
      }
    }
  }

  .btn-warp {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  ::v-deep.dialogHd {
    text-align: center;
  }
}
</style>
